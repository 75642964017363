import React from "react";

import { SQLServerStartGuide } from "../../../components/database/sqlServer/StartGuide";
import { SQLServerPageContent } from "../../../components/database/sqlServer/PageContent";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/SQLServerTopBanner";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/sql_server_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/sql-server/";

const SQLServer = ({ location }) => (
  <Layout location={location}>
    <Seo
      title="Instant REST APIs on Microsoft SQL Server from Hasura"
      description="Hasura connects to existing Microsoft SQL Server Database and gives instant REST CRUD APIs."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner location={location}
      title="Instant REST API for SQL Server"
    />
    <SQLServerStartGuide />
    <SQLServerPageContent
      title="REST"
     />
  </Layout>
);

export default SQLServer;
