import React, { Fragment } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import ArrowRight from "../../globals/icons/ArrowRight";

import { StyledSubTitle2, StyledDesc1 } from "../shared/StyledTypography";
import { flexCenter, justifyCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import getStartedBg from "./images/get-started-bg.svg";

const StyledProductGetStartedWrapper = styled.div`
  background-image: url(${getStartedBg});
  background-repeat: no-repeat;
  box-shadow: 4px 14px 44px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 70px;
  text-align: center;
  .arrow {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    .arrow {
      transform: translateX(5px);
    }
  }
  ${mq.below.md} {
    padding: 50px 32px;
  }
`;

const ProductGetStarted = ({ linkUrl, title, linkContent, internalLink }) => {
  return (
    <Fragment>
      {internalLink ? (
        <Link to={linkUrl}>
          <StyledProductGetStartedWrapper>
            <StyledSubTitle2 paddingBottom="pb16">{title}</StyledSubTitle2>
            <StyledDesc1 variant="sky_80" css={flexCenter + " " + justifyCenter}>
              {linkContent}
              <ArrowRight variant="sky_80" size="xs" className="arrowInline"/>
            </StyledDesc1>
          </StyledProductGetStartedWrapper>
        </Link>
      ) : (
        <a href={linkUrl}>
          <StyledProductGetStartedWrapper>
            {
              linkContent ? (
                <StyledSubTitle2 paddingBottom="pb16">{title}</StyledSubTitle2>
              ) : (
                <StyledSubTitle2 css={flexCenter}>{title}<ArrowRight variant="sky_80" size="xs" className="arrow"/></StyledSubTitle2>
              )
            }

            {
              linkContent ? (
                <StyledDesc1 variant="sky_80" css={flexCenter + " " + justifyCenter}>
                  {linkContent}
                  <ArrowRight variant="sky_80" size="xs" className="arrow"/>
                </StyledDesc1>
              ) : null
            }
          </StyledProductGetStartedWrapper>
        </a>
      )}
    </Fragment>
  );
};

export default ProductGetStarted;
