import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import StyledButton from "../../shared/StyledButton";

import { Icon } from '../../../globals/icons';
import { StyledTitle4, StyledSubTitle2, StyledDesc1, StyledDesc3 } from "../../shared/StyledTypography";
import { COLORS } from "../../../globals/designSystem";
import { flexCenter } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

import sqlServerImg from "../images/sql-server-logo.svg";

const StyledTopBanner = styled.div`
  max-width: 780px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 110px;
  .dbBrandWrapper {
    align-items: center;
    display: flex;
    margin-bottom: 25px;
    .dbBrand {
      width: 160px;
      margin-right: 16px;
    }
    .success-tag {
      background-color: ${COLORS.green_10};
      border-radius: 4px;
      color: ${COLORS.green_70};
      line-height: 25px;
      text-align: center;
      padding: 2px 6px;
    }
  }
  .buttonWrapper {
    padding-top: 32px;
    padding-bottom: 16px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`;

const TopBanner = ({title}) => {
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledTopBanner>
          <div>
            <div className="dbBrandWrapper">
              <img src={sqlServerImg} alt="hasura-sql-server-img" className="dbBrand" />
              <StyledDesc3 className="success-tag" fontWeight="font_600">AVAILABLE</StyledDesc3>
            </div>
            <StyledTitle4>{title}</StyledTitle4>
          </div>
          <div>
            <StyledSubTitle2>Hasura now supports Microsoft SQL Server.</StyledSubTitle2>
            <div className="buttonWrapper">
              <a href="https://cloud.hasura.io/signup?pg=sql-server&plcmt=body&cta=get-started-in-30-seconds&tech=default">
                <StyledButton variant="blue" size="md">Get Started in 30 seconds</StyledButton>
              </a>
            </div>
            <StyledDesc1 className="pt24">
              <a href="https://hasura.io/docs/latest/graphql/core/databases/ms-sql-server/index.html"
              id="download-link" css={flexCenter}>
                Run locally with Docker
                <Icon className="arrow" variant="arrow_right" color="sky_80" size="sm" />
              </a>
            </StyledDesc1>
          </div>
        </StyledTopBanner>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default TopBanner;
