import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import ProductGetStarted from "../../product/productgetstarted";

import { Icon } from '../../../globals/icons';
import { StyledSubTitle2, StyledDesc1 } from "../../shared/StyledTypography";
import { flexCenter } from "../../shared/CommonStyled";
import { ELEVATION } from "../../../globals/designSystem";
import { mq } from "../../../globals/utils";

import sql101Illus from "../../stylegraphql/images/sql-101-illus.svg";
import salesCall from "../../stylegraphql/images/sales_call.svg";

const StyledSQLServerWrapper = styled.div`
  max-width: 820px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  .startGuideList {
    display: grid;
    box-shadow: ${ELEVATION.l_2};
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(0.98);
      .arrow {
        transform: translateX(5px);
      }
    }
    .alignSelfStart {
      align-self: start;
    }
    .alignSelfEnd {
      align-self: end;
      padding: 24px 0;
    }
    .plr24 {
      padding: 0 24px;
    }
    .startGuideImg {
      padding-bottom: 16px;
      img {

      }
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`;

export const SQLServerStartGuide = () => (
  <StyledContainerWrapper>
    <StyledSQLServerWrapper>
      <Link className="startGuideList" to="/events/webinar/hasura-sql-server/">
        <div className="alignSelfStart">
          <div className="startGuideImg">
            <img src={sql101Illus} alt="SQL Server" />
          </div>
          <div className="plr24">
            <StyledSubTitle2 paddingBottom="pb16">SQL Server 101: Watch our getting started demo</StyledSubTitle2>
            <StyledDesc1>Live demo on how to get started with your existing SQL Server databases.</StyledDesc1>
          </div>
        </div>
        <div className="alignSelfEnd">
          <div className="plr24">
            <StyledDesc1 variant="sky_80" css={flexCenter}>Watch the Webinar <Icon className="arrow" variant="arrow_right" color="sky_80" size="sm" /></StyledDesc1>
          </div>
        </div>
      </Link>
      <Link className="startGuideList" to="/contact-us/?type=hasuraenterprise">
        <div className="alignSelfStart">
          <div className="startGuideImg">
            <img src={salesCall} alt="SQL Server" />
          </div>
          <div className="plr24">
            <StyledSubTitle2 paddingBottom="pb16">Are you looking to modernise your existing stack?</StyledSubTitle2>
            <StyledDesc1>Talk to our sales team to discuss how to incrementally migrate and get instantly
            productive with Hasura & your existing SQL Server workloads.</StyledDesc1>
          </div>
        </div>
        <div className="alignSelfEnd">
          <div className="plr24">
            <StyledDesc1 variant="sky_80" css={flexCenter}>Talk to Sales <Icon className="arrow" variant="arrow_right" color="sky_80" size="sm" /></StyledDesc1>
          </div>
        </div>
      </Link>
    </StyledSQLServerWrapper>
    <StyledSectionWrapper>
      <ProductGetStarted
        linkUrl="https://hasura.io/docs/latest/graphql/core/databases/ms-sql-server/index.html"
        title="SQL Server’s compatibility with Hasura in the official documentation"
        linkContent="Read more"
      />
    </StyledSectionWrapper>
  </StyledContainerWrapper>
);
