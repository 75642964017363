import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";

import { StyledTitle4, StyledSubTitle1, StyledDesc1 } from "../../shared/StyledTypography";
import { COLORS } from "../../../globals/designSystem";
import { mq } from "../../../globals/utils";

import sqlServerImgOne from "../images/sql_server_01.svg";
import sqlServerImgTwo from "../images/sql_server_02.svg";
import sqlServerImgThree from "../../allfeatures/images/fetch-rest-authorization.png";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const StyledSQLServerFeatureWrapper = styled.div`
  .sqlServerTitle {
    text-align: center;
    padding-bottom: 60px;
  }
  .sqlServerFeatureWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 120px;
    .alignSelfCenter {
      align-self: center;
    }
  }
  ${mq.between("md", "lg")} {
    .sqlServerFeatureWrapper {
      grid-gap: 60px;
    }
  }
  ${mq.below.md} {
    .sqlServerTitle {
      text-align: left;
      padding-bottom: 40px;
    }
    .sqlServerFeatureWrapper {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }
`;

export const SQLServerPageContent = ({title}) => (
  <>
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledSQLServerFeatureWrapper>
          <StyledTitle4 className="sqlServerTitle">Getting started with SQL Server</StyledTitle4>
          <div className="sqlServerFeatureWrapper">
            <div className="alignSelfCenter">
              <StyledSubTitle1 paddingBottom="pb16">Getting up-and-running with Hasura</StyledSubTitle1>
              <StyledDesc1>
                <ul>
                  <li>Connect Hasura to your new or existing SQL Server database for an instant {title} API in 30 seconds.</li>
                  <li>Get started on Hasura Cloud or download our Docker container.</li>
                </ul>
              </StyledDesc1>
            </div>
            <div className="alignSelfCenter">
              <img src={sqlServerImgOne} alt="Hasura SQL Server" />
            </div>
          </div>
        </StyledSQLServerFeatureWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledSQLServerFeatureWrapper>
          <div className="sqlServerFeatureWrapper">
            <div className="alignSelfCenter">
              <StyledSubTitle1 paddingBottom="pb16">Extend your existing SQL Server database</StyledSubTitle1>
              <StyledDesc1>
                <ul>
                  <li>Connect your existing SQL Server with modern web and mobile frameworks with a single {title} endpoint.</li>
                  <li>Incrementally migrate to Hasura by integrating your existing business logic with Hasura using Actions and Event triggers.</li>
                  <li><span>Bring in data from other data sources and APIs using{" "} <a href="https://hasura.io/docs/latest/graphql/core/remote-schemas/index.html#remote-schemas">Remote Joins</a>.</span></li>
                </ul>
              </StyledDesc1>
            </div>
            <div className="alignSelfCenter">
              <img src={sqlServerImgTwo} alt="Hasura SQL Server" />
            </div>
          </div>
        </StyledSQLServerFeatureWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
    <StyledSectionWrapperLightGray>
      <StyledContainerWrapper>
        <StyledSQLServerFeatureWrapper>
          <div className="sqlServerFeatureWrapper">
            <div className="alignSelfCenter">
              <StyledSubTitle1 paddingBottom="pb16">Authorization for {title}, out-of-the-box</StyledSubTitle1>
              <StyledDesc1>
                <ul>
                  <li>Hasura’s built-in RLS style authorization engine allows you to conveniently specify authorization rules at a model level, and safely expose the {title} API to developers inside or outside your organization.</li>
                  <li>Replace direct database access with a data API that you can manage easily.</li>
                </ul>
              </StyledDesc1>
            </div>
            <div className="alignSelfCenter">
              <img src={sqlServerImgThree} alt="Hasura SQL Server" />
            </div>
          </div>
        </StyledSQLServerFeatureWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperLightGray>
  </>
);
